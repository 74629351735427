import React from 'react';
import {Navbar, Card, Row, Col, Modal} from 'react-materialize';
import {Link} from 'react-router-dom';
var moment = require("moment");
import UdIcon from './ud-icon.jsx';

import {fetchGet, fetchPost} from './services/fetch-service.jsx';

export default class License extends React.Component {
    constructor() {
        super();

        this.state = {
            licenseRequired: false,
            licenseExpired: false,
            existingLicense: false,
            expirationDate: "",
            numberOfSeats: 0,
            licenseText: "",
            error: ""
        }
    }

    componentDidMount() {
        fetchGet("/license/status", function (status) {
            if (status === 0) {
                this.setState({
                    licenseRequired: true
                });

                return;
            }

            if (status === 2) {
                this.setState({
                    licenseExpired: true
                });

                return;
            }

            fetchGet("/license/", function (result) {
                var licenseMoment = new moment(result.endDate);

                this.setState({
                    expirationDate: licenseMoment.format('lll'),
                    numberOfSeats: result.seatNumber,
                    trial: result.isTrial
                })
            }.bind(this));
        }.bind(this));
    }

    existingLicense() {
        this.setState({
            existingLicense: true
        });
    }

    onLicenseTextChanged(e) {
        this.setState({
            licenseText: e.target.value
        })
    }

    installLicense() {

        if (this.state.licenseText === "") {
            this.setState({error: "Please enter your license."})
            return
        }

        fetchPost("/license", {license: this.state.licenseText}, function (result) {

            if (result.error) {
                this.setState({
                    error: result.error
                })
                return;
            }

            var licenseMoment = new moment(result.endDate);
            
            this.setState({
                expirationDate: licenseMoment.format('lll'),
                numberOfSeats: result.seatNumber,
                trial: result.isTrial,
                licenseSent: false,
                licenseRequired: false,
                error: ""
            })
         }.bind(this))
    }

    renderExistingLicense() {
        return {
            body:  <Row>
                        <Col s={12}>
                            <Row>
                                <strong><UdIcon icon="clock-o"/> Trial Time Has Expired</strong>
                            </Row>
                            <Row>
                                <p>An unlicensed version of Universal Dashboard only runs for up to one hour. </p> 
                                <p>To continue using an unlicensed version of Universal Dashboard you can restart your server by stopping the current server with Stop-UDDashboard and then running Start-UDDashboard again.</p>
                                <p>If you'd like to run Universal Dashboard for longer than one hour, you can purchase a license or enter a license you've already purchased.</p>
                            </Row>
                            <Row>
                                <textarea id="txtLicense" onChange={this.onLicenseTextChanged.bind(this)} value={this.state.licenseText} style={{height: '14em', marginBottom: '10px'}}></textarea>
                            </Row>
                        </Col>
                    </Row>,
            actions: [
                <a href="https://ironmansoftware.com/product/powershell-universal-dashboard/" className="white-text">Buy a License</a>,
                <a href="#!" id="installLicense" className="waves-effect waves-blue btn blue white-text" onClick={this.installLicense.bind(this)}>Install License</a>
            ]
        }
    }

    renderLicenseExpired() {
        return {
            body: <div><p>Your license has expired. Please visit <a href="https://ironmansoftware.com">Ironmansoftware.com</a> to purchase a new license.</p>
                       <textarea onChange={this.onLicenseTextChanged.bind(this)} value={this.state.licenseText}></textarea>
                   </div>,
            actions: [
                <a href="#!" className="modal-action waves-effect waves-blue btn-flat white-text" onClick={this.installLicense.bind(this)}>Install License</a>
            ]
        }
    }

    renderLicenseInformation() {
        var validText = this.state.numberOfSeats > 1 ? <span>Valid for { this.state.numberOfSeats } machines.</span> : <span>Valid for { this.state.numberOfSeats } machine.</span> 
        if (this.state.numberOfSeats > 100000) {
            validText = <span>Valid for an unlimited number of machines.</span>
        }

        return {
            body: <div>
                    <h5><UdIcon icon="calendar-check-o" /> Free updates until: { this.state.expirationDate }</h5>
                    <h5><UdIcon icon="list-ol" /> {validText}</h5>
                   </div>,
            actions: [
                
                <a href="https://docs.universaldashboard.io">View Documentation</a>,
                <Link to={"/"}>View Dashboard</Link>
            ]
        }
    }

    render() {
        var modalParts = null;
        if (this.state.licenseRequired) {
            modalParts = this.renderExistingLicense();
        }
        else if (this.state.licenseExpired) {
            modalParts = this.renderLicenseExpired();
        }
        else if (this.state.licenseSent) {
            modalParts = this.renderLicenseSent();
        }
        else {
            modalParts = this.renderLicenseInformation();
        }

        var error = null;
        if (this.state.error !== "") {
            error = <Row><Col s={12} className="red-text"><strong>{this.state.error}</strong></Col></Row>;
        }

        return (
            <div style={{paddingBottom: '20%', backgroundColor: '#535252'}}>
                <Row >
                    <Col s={10} offset="s1" style={{marginTop:'12%'}}>
                    <div className="card large" style={{background: "#252525"}}>
                        <div className="card-content white-text" style={{background: "#252525"}}>
                            <span className="card-title">PowerShell Universal Dashboard Licensing</span>
                            {error}
                            {modalParts.body}
                        </div>
                        <div className="card-action white-text" style={{background: "#1c1c1c"}}>
                            {modalParts.actions}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        );
    }
}