import React from 'react';
import SplitPane from 'react-split-pane';

export default class UDSplitPane extends React.Component {
    render() {
        var { content } = this.props;
        
        if (content.length !== 2) {
            return <div>Split pane supports exactly two components.</div>
        }

        var children = content.map(x => UniversalDashboard.renderComponent(x));

        return [
            <style dangerouslySetInnerHTML={{__html: `
            .Resizer {
                background: #000;
                opacity: .2;
                z-index: 1;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -moz-background-clip: padding;
                -webkit-background-clip: padding;
                background-clip: padding-box;
            }
        
             .Resizer:hover {
                -webkit-transition: all 2s ease;
                transition: all 2s ease;
            }
        
             .Resizer.horizontal {
                height: 11px;
                margin: -5px 0;
                border-top: 5px solid rgba(255, 255, 255, 0);
                border-bottom: 5px solid rgba(255, 255, 255, 0);
                cursor: row-resize;
                width: 100%;
            }
        
            .Resizer.horizontal:hover {
                border-top: 5px solid rgba(0, 0, 0, 0.5);
                border-bottom: 5px solid rgba(0, 0, 0, 0.5);
            }
        
            .Resizer.vertical {
                width: 11px;
                margin: 0 -5px;
                border-left: 5px solid rgba(255, 255, 255, 0);
                border-right: 5px solid rgba(255, 255, 255, 0);
                cursor: col-resize;
            }
        
            .Resizer.vertical:hover {
                border-left: 5px solid rgba(0, 0, 0, 0.5);
                border-right: 5px solid rgba(0, 0, 0, 0.5);
            }
            .Resizer.disabled {
              cursor: not-allowed;
            }
            .Resizer.disabled:hover {
              border-color: transparent;
            }
            .SplitPane {
                position: relative !important;
                ${this.props.split === 'vertical' ? 'height: 100ch !important' : ''};
            }
            `}} />,
            <SplitPane {...this.props}>{children}</SplitPane>
        ]
    }
}