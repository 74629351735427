import React from 'react';

export default class NoRemote extends React.Component {
    render() {
        let overlay = {
            position: 'fixed',
            width: '100%',
            height: '100%', 
            top: 0, 
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 2,
            display: 'block'
        };

        let text = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: '50px',
            color: 'white',
            userSelect: 'none',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center'
        };

        return (
            <div style={overlay}>
                <div style={text}>Universal Dashboard Enterprise cannot be used remotely when unlicensed.</div>
            </div>
        )
    }
}