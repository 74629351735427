import React from 'react';
import {fetchPost, fetchGet} from './services/fetch-service.jsx';
import {getApiPath} from 'config'
import {Row, Col} from 'react-materialize';
import UdIcon from './ud-icon.jsx';
import Loading from './ud-loading.jsx';
import renderComponent from './services/render-service.jsx';

export default class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            password: '',
            username: '',
            model: null,
            errorMessage: '', 
            loading: false,
            returnUrl: '/'
        }
    }

    componentWillMount() {
        fetchGet(`/login-page`, function(model) {
            if (model === null) {
                fetchPost("/login", {}, () => this.props.history.push("/"));
            }

            if (model.passThru) {
                this.props.history.push("/");
            }

            document.title = model.title;

            var returnUrl = "/";
            if (this.props.location && this.props.location.state) {
                returnUrl = this.props.location.state.returnUrl;
            }

            this.setState({
                model,
                returnUrl
            })
        }.bind(this));
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
            errorMessage: ""
        })
    }

    onUserNameChange(e) {
        this.setState({
            username: e.target.value,
            errorMessage: ""
        })
    }

    onLogin() {
        if (this.state.password === '' || this.state.username === '') {
            this.setState({
                errorMessage : "Username and password are required."
            })
            return;
        }

        this.setState({
            loading: true
        })

        fetchPost(`/login`, {
            password: this.state.password,
            username: this.state.username
        }, result => {
            this.setState({
                loading: false
            })

            if (result == null) {
                this.setState({
                    errorMessage: "Bad username or password."
                })
            }
            else if (result.error) {
                this.setState({
                    errorMessage: result.error
                })
            }
            else if (!result.success) {
                this.setState({
                    errorMessage: result.errorMessage
                })
            } else {
                if (result.redirectUrl) {
                    window.sessionStorage.removeItem("returnUrl");
                    this.props.history.push(result.redirectUrl)
                } else {
                    this.props.history.push(this.state.returnUrl)
                }   
            }
            
        });
    }

    onKeyUp(e) {
        if (e.keyCode === 13) {
            this.onLogin();
        }
    }

    renderAuthenticationMethod(method) {
        var error = null;
        if (this.state.errorMessage) {
            error = <span id="errorMessage" className="red white-text left-align" style={{padding:'5px'}}><UdIcon icon={'times-circle'} /> {this.state.errorMessage}</span>
        }

        // Forms
        if (method.type === 0) {
            return [<div className="card-content">
                        <input type="text" placeholder="Username" name="username" value={this.state.username} onChange={this.onUserNameChange.bind(this)} onKeyUp={this.onKeyUp.bind(this)} style={{background: this.state.model.loginFormBackgroundColor, color: this.state.model.loginFormFontColor}}/>
                        <input type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.onPasswordChange.bind(this)} onKeyUp={this.onKeyUp.bind(this)} style={{background: this.state.model.loginFormBackgroundColor, color: this.state.model.loginFormFontColor}}/>
                        {error}
                    </div>,
                    <div className="card-action">
                        <a href="#!" onClick={this.onLogin.bind(this)}>Login</a>
                    </div>]
        }

        //OAuth
        if (method.type == 1) {
            switch(method.oauthProvider){
                //Facebook
                case 0:
                return <form action={getApiPath() + `/signin`} method="post">
                    <input type="hidden" name="Provider" value="Facebook"/>
                    <input type="hidden" name="ReturnUrl" value={this.state.returnUrl} />
                    <button className="btn btn-success center-align" type="submit" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="facebook-login"><i className="fa fa-facebook fa-lg left"></i> Sign in with Facebook</button>
                </form>
                //Twitter
                case 1:
                return <form action={getApiPath() + `/signin`} method="post">
                    <input type="hidden" name="Provider" value="Twitter"/>
                    <input type="hidden" name="ReturnUrl"  value={this.state.returnUrl}/>
                    <button className="btn btn-success center-align" type="submit" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="twitter-login"><i className="fa fa-twitter fa-lg left"></i> Sign in with Twitter</button>
                </form>
                //Google
                case 2:
                return <form action={getApiPath() + `/signin`} method="post">
                    <input type="hidden" name="Provider" value="Google"/>
                    <input type="hidden" name="ReturnUrl"  value={this.state.returnUrl}/>
                    <button className="btn btn-success center-align" type="submit" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="google-login"><i className="fa fa-google fa-lg left"></i> Sign in with Google</button>
                </form>
                //Microsoft
                case 3:
                return <form action={getApiPath() + `/signin`} method="post">
                    <input type="hidden" name="Provider" value="Microsoft"/>
                    <input type="hidden" name="ReturnUrl" value={this.state.returnUrl}/>
                    <button className="btn btn-success center-align" type="submit" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="microsoft-login"><i className="fa fa-windows fa-lg left"></i> Sign in with Microsoft</button>
                </form>
                //Azure Active Directory
                case 4:
                return <form action={getApiPath() + `/signin`} method="post">
                    <input type="hidden" name="Provider" value="AzureAD"/>
                    <input type="hidden" name="ReturnUrl" value={this.state.returnUrl}/>
                    <button className="btn btn-success center-align" type="submit" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="azuread-login"><i className="fa fa-windows fa-lg left"></i> Sign in with Azure Active Directory</button>
                </form>
            }
        }

        //OpenID or WSFed
        if (method.type == 5 || method.type == 4) {
            var authUrl = window.sessionStorage.getItem("authUrl");
            return <a href={authUrl} className="btn btn-success center-align" style={{margin:'10px', background: this.state.model.loginButtonBackgroundColor, color: this.state.model.loginButtonFontColor}} id="openid-login"> Sign in with {method.serviceName}</a>
        }
    }

    renderPreloader() {
        return (
        <div id="loadingText" className="center-align">
            <div className="row" style={{paddingTop: '20px'}}>
                <div className="preloader-wrapper big active">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <h5>{this.state.model.loadingText}</h5>
            </div>
        </div>)
    }

    render() {
        if (this.state.model == null) {
            return <Loading />
        }

        var content = null;

        if (this.state.loading) {
            content =  this.renderPreloader();
        } else {
            content = this.state.model.authenticationMethod.map(x => this.renderAuthenticationMethod(x));
        }

        return <div className="valign-wrapper" style={{height: "100vh", background: this.state.model.pageBackgroundColor}}>
                <Row style={{marginTop: "-200px"}}>
                    {renderComponent(this.state.model.logo)}
                    <h3>{this.state.model.welcomeText}</h3>
                    <div className="card horizontal" style={{background: this.state.model.loginFormBackgroundColor, color: this.state.model.loginFormFontColor}}>
                        <div className="valign-wrapper" style={{padding:'20px', borderRight: "1px solid rgba(160,160,160,0.2)"}}><UdIcon icon="Lock" size="5x" /></div>
                        <div className="card-stacked">
                            {content}
                        </div>
                    </div>
                </Row>
            </div>
    }
}