import React from 'react';

export default class Watermark extends React.Component {

    constructor() {
        super();

        var dismissed = sessionStorage.getItem("udwatermark");

        this.state = {
            open: dismissed == null
        }
    }

    onClose() {
        sessionStorage.setItem("udwatermark", "dimissed");

        this.setState({
            open: false
        })
    }

    render() {

        var display = "none";
        if (this.state.open) {
            display = 'block'
        }
        return (
            <div className="row"  style={{
                position: 'fixed',
                bottom: 0,
                zIndex: 1000,
                right: 0,
                display: display
            }}>
                <div className="col s12 l6">
                    <div className="card blue-grey darken-1">
                            <div className ="card-content white-text">
                                <span className="card-title">Universal Dashboard Trial</span>
                                This dashboard will run for {this.props.timeLeft.toFixed(0)} more minutes. 
                                You can extend this time by restarting the dashboard. You can remove this message by purchasing a license.
                            </div>
                        <div class="card-action">
                            <a href="https://ironmansoftware.com/powershell-universal-dashboard/" style={{color:'#FFFFFF'}}>Buy Now</a>
                            <a href="https://docs.universaldashboard.io" style={{color:'#FFFFFF'}}>Documentation</a>
                            <a href="https://forums.universaldashboard.io" style={{color:'#FFFFFF'}}>Community</a>
                            <a href="#!" style={{color:'#FFFFFF'}} onClick={this.onClose.bind(this)}>Close</a>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}